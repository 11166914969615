<template>
	<div class="page">
		<div class="title">为满足政策要求</div>
		<div class="desc">请确保本人操作且全程将录音录像，望您理解！</div>
		<div class="banner">
			<img src="../static/img/Group 501@2x.png" alt="">
		</div>
		<div class="box">
			<div class="item">
				<img src="../static/img/Group466@2x.png" alt="">
				<div>请正对屏幕，脸在检测框内</div>
			</div>
			<div class="item">
				<img src="../static/img/Group 499@2x.png" alt="">
				<div>调大手机音量，避免环境嘈杂</div>
			</div>
			<div class="item">
				<img src="../static/img/Group 533@2x.png" alt="">
				<div>为保证录制质量，请不要佩戴耳机</div>
			</div>
		</div>
		
		<div class="bottomBox">
			<van-button class="btn" type="info" size="large" @click="jump('/video')">采集本人人脸</van-button>
			<div class="txt" @click="changeType">
				<img src="../static/img/icon_unchecked@2x.png" alt="" class="radio" v-if="!radio">
				<img src="../static/img/voicend.png" alt="" class="radio" v-if="radio">
				我已认真阅读并同意<span class="rule" @click.stop="jump('/privacyPolicy')">《双录隐私保护协议》</span>
			</div>
		</div>
		
	</div>
</template>

<script>
	export default{
		data(){
			return{
				radio:false,
				userInfo:'',
				token:'',
				id:''
			}
		},
		mounted() {
			if(this.$route.query){
				this.radio = this.$route.query.isCheck
			}
			this.id = this.$route.query.id
			if(!sessionStorage.getItem('token')){
				sessionStorage.setItem('token','Bearer ' + this.$route.query.token)
			}
			if(sessionStorage.getItem('from') == 'policy'){
				sessionStorage.removeItem('from')
			}else{
				this.appInit()
			}
			// this.getErrCodeMsg()
		},
		methods:{
			jump(url){
				if(url == '/video'){
					if(!this.radio){
						this.$toast.fail('请阅读并勾选隐私条款')
						return false
					}
				}

				this.$router.push({
					path:url,
					query:{
						from:"home"
					}
				})
			},
			changeType(){
				this.radio = !this.radio
			},
			appInit(){
				let params = {
					dateTime:new Date().getTime()
				}
				params.sign = this.util.paramsFormat(params,'feioou')
				this.api.appInit(params).then(res=>{
					if(res.code == 200){
						document.title = res.data.title
						sessionStorage.setItem('userInfo',JSON.stringify(res.data))
					}
				}).catch(err=>{
					console.log(err.data.message)
					this.$dialog({
						title:'提示',
						message:err.data.message,
						className:'dialogTxt',
						showConfirmButton:false,
						showCancelButton:false
					})
				})
			},
			// getErrCodeMsg(){
			// 	this.api.getErrCode({}).then(res=>{
			// 		if(res.code == 200){
			// 			sessionStorage.setItem('errCode',JSON.stringify(res.data))
			// 		}
			// 	})
			// }
		}
	}
	
</script>


<style lang="less" scoped>
	.page{
		padding: 24px;
		text-align: left;
		color: #000;
	}
	.title{
		font-size: 22px;
		font-weight: bold;
		margin-bottom: 5px;
		color: #333;
		line-height: 30px;
	}
	.desc{
		font-size: 14px;
		color: #999;
	}
	.banner{
		width: 274px;
		margin: 0 auto;
		img{
			width: 100%;
		}
	}
	.box{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		width: 274px;
		margin: 0 auto;
		.item{
			width: 100%;
			display: flex;
			justify-content: center;
			margin: 8px 0;
			padding: 0 12px;
			img{
				width: 24px;
				height: 24px;
				margin-right: 16px;
			}
			div{
				width: 100%;
				color: #666;
				font-size: 14px;
				font-weight: bold;
				display: flex;
				align-items: center;
			}
		}
	}
	.bottomBox{
		position: fixed;
		width: 100%;
		bottom: 25px;
		left: 0;
		box-sizing: border-box;
		padding: 0 24px;
		.btn{
			background-color: #5164FF;
			border-color: #5164FF;
			height: 44px;
			border-radius: 4px;
		}
		.txt{
			margin-top: 12px;
			color: #999;
			font-size: 12px;
			display: flex;
			justify-content: center;
			align-items: center;
			.radio{
				width: 24px;
				margin-right: 2px;
			}
			.rule{
				color: #5164FF;
			}
		}
	}
	@media (max-width:376px){
		.page{
			padding: 12px 24px;
		}
		.banner{
			width: 80%;
			margin: 0 auto;
		}
		.box .item img{
			width: 36px;
			height: 36px;
		}
		.box .item div{
			font-size: 12px;
		}
	}
</style>